import Player from '@vimeo/player';

const LOADED_CLASS = 'is-loaded';
const REVEAL_DELAY = 700; // ms (var(--a-t-lg)
const REVEALED_CLASS = 'is-revealed';
const SECTION_IN_VIEW_CLASS = 'section-in-view';
const FOOTER_IN_VIEW_CLASS = 'footer-in-view';
const MODAL_ACTIVE_CLASS = 'modal-is-active';

const start = Date.now();

document.addEventListener('DOMContentLoaded', () => {
	const header = document.querySelector('main > header');
	const bgVideo = header.querySelector('video');
	const playButton = header.querySelector('button');
	const section = document.querySelector('main > section');
	const sectionObserver = (new IntersectionObserver(([{ isIntersecting }], o) => {
		if (document.body.classList.toggle(SECTION_IN_VIEW_CLASS, isIntersecting)) {
			o.unobserve(section);
		}
	}, { threshold: 0.5 })).observe(section);
	const footer = document.querySelector('main > footer');
	const footerObserver = (new IntersectionObserver(([{ isIntersecting }]) => {
		document.body.classList.toggle(FOOTER_IN_VIEW_CLASS, isIntersecting);
	}, { threshold: 0.5 })).observe(footer);
	const modal = document.querySelector('.modal');
	const videoPlayer = new Player(modal.querySelector('div'));

	bgVideo.onplaying = e => {
		document.body.classList.add(LOADED_CLASS);

		setTimeout(() => {
			document.body.classList.add(REVEALED_CLASS);
		}, REVEAL_DELAY);
	};
	playButton.onclick = () => {
		if (document.body.classList.toggle(MODAL_ACTIVE_CLASS)) {
			videoPlayer.play();
		} else {
			videoPlayer.pause();
		}
	}
-	document.addEventListener('keyup', ({ keyCode }) => {
		if (keyCode === 27) {
			document.body.classList.remove(MODAL_ACTIVE_CLASS);
			videoPlayer.pause();
		}
	});

	const end = Date.now();

	setTimeout(() => {
		bgVideo.play();
	}, 3000 - (end - start));
});
